import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { currentUser } from './recoil/atoms'
import { createTheme, responsiveFontSizes, styled, ThemeProvider } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { useOktaAuth } from '@okta/okta-react';
import LoadingPage from './pages/LoadingPage'
import { SnackbarProvider, MaterialDesignContent } from 'notistack'
import AppMain from './AppMain'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-info': {
    display: 'flex',
    flexWrap: 'nowrap'
  },
}));

const StyledChatSnackbar = styled(MaterialDesignContent)((theme) => ({
  backgroundColor:  theme.theme.palette.chat.main
}));

// const colors = {
//   'SEA': '#0099ff',
//   'PFM': 'rgb(111,80,161)',
//   'VTV': 'rgb(224,174,68)',
//   'EWX': '#0099ff',
//   'CPT': '#0099ff',
// }

// const seasi = [
//   '#454f8c',
//   '#6f50a1',
//   '#a54c9d',
//   '#14b9e2',
// ]

const themes = {
  seasi: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#7A3493' //'#454f8c'
      },
      secondary: {
        main: '#ED254E' //'#B80C09'
      },
      chat: {
        main: '#707BBB'
      },
      grey: {
        main: grey[300]
      },
      lightgrey: {
        main: grey[100]
      },
      toolbar: '#343A40'
    },
    overrides: {
      MUIRichTextEditor: {
        root: {
          width: "100%",
          border: "1px solid #bdbdbd",
          borderRadius: '4px',
          boxSizing: 'border-box'
        },
        editorContainer: {
          padding: '0.6rem',
          margin: 0,
          boxSizing: 'border-box'
        },
        placeHolder: {
          position: 'relative'
        },
        toolbar: {
          display: 'flex',
          justifyContent: 'space-around',
          marginBottom: '0.5rem',
          paddingBottom: '0.5rem',
          borderBottom: '1px solid #e0e0e0'
        }
      }
    }
  })),
  pfm: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#6C54A4'
      },
      secondary: {
        main: '#EE6352'
      },
      grey: {
        main: grey[300],
      },
      // all: '#BA274A',//'#92374D',
      // male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
      // female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
      // other: '#E1BC29',
      // genders: {
      //     male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
      //     female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
      //     other: '#E1BC29' //'#8594ca', '#deacae', '#bda175'
      // },
      // respondents: '#17768D',
      // dial: '#7C9EB2', //677BA8
      // selectedDial: '#17768D',//'#008bf9',
      // tuneIn: '#3BB273',//'#58BC82',
      // tuneOut: '#E15554',//'#DB504A'
    },
  })),
  vvtv: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#be8b1f'
      },
      secondary: {
        main: '#B91372'//'#D34F73'
      },
      grey: {
        main: grey[300],
      },
    },
  })),
  ew: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#0099ff'
      },
      secondary: {
        main: '#D64045' //'#E26D5C'
      },
      grey: {
        main: grey[300],
      },
    },
  })),
  cpt: responsiveFontSizes(createTheme({
    palette: {
      // primary: {
      //   main: '#6C54A4'
      // }
    },
  })),
};

// const validTeams = [
//   'seasi', 'pfm', 'vvtv', 'ew', 'cpt'
// ]

const fullAccess = [
  "session",
  "audience",
  "tc",
  "balance",
  "client",
  "dial",
  "video"
]

const theme = 'seasi'

const App = React.memo((props) => {
  //console.log('render app', props)
  // const [theme, setCurrentTheme] = useState('seasi')
  // const setCurrentUser = useSetRecoilState(currentUser)
  const [user, setCurrentUser] = useRecoilState(currentUser)
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    oktaAuth.getUser().then((user) => {
      //console.log('got current user: ', user)
      if (!user) throw new Error('UNDEFINED USER')
      // if (!user.groups) throw new Error('UNDEFINED GROUPS')
      if ((!user.accessLevels || !user.accessLevels.length) && !user.fullAdmin) throw new Error('NO ACCESS LEVELS DEFINED')
      if (user.fullAdmin) user.accessLevels = fullAccess
      user.chimeUsername = `${user.given_name} (Staff)`
      // let group = user.groups[0].toLocaleLowerCase().replace(/livetrac|( ?- ?)/gi, '')
      // if (!validGroups.includes(group)) throw 'INVALID GROUP'
      // const admin = /admin/gi.test(team);
      // user.team = team.replace(/( ?admin ?)/gi, '')
      // user.admin = admin
      // setCurrentTheme(Object.keys(themes).includes(team) ? team : 'seasi')
      setCurrentUser(user)
    }).catch((e) => {
      console.log(e)
      oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
    })
  }, [oktaAuth, setCurrentUser])

  return (theme && user) ? <ThemeProvider theme={themes[theme]}>
    <SnackbarProvider
      Components={{
        info: StyledMaterialDesignContent,
        chat: StyledChatSnackbar
      }}
      maxSnack={10} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }} autoHideDuration={6000}>
      <AppMain {...props} />
    </SnackbarProvider>
  </ThemeProvider> : <LoadingPage />;
})

export default App;
