import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useLocalStorage, clearLocalStorage } from '../hooks/hooks'
import { useClipboard } from 'use-clipboard-copy';
import { AppBar, Tabs, Tab, Typography, Badge, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, TextField, Select, MenuItem } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import ReplayIcon from '@mui/icons-material/Replay';
// import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import StopIcon from '@mui/icons-material/Stop'
import AssignmentIcon from '@mui/icons-material/Assignment';
import AnnouncementIcon from '@mui/icons-material/Announcement'
import ChatIcon from '@mui/icons-material/Chat'
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import BlockIcon from '@mui/icons-material/Block'
import RefreshIcon from '@mui/icons-material/Refresh';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
// import MaterialTable, { MTableActions, MTableBodyRow } from "material-table"
import MaterialTable, { MTableActions, MTableBodyRow } from "@material-table/core"
import { withSuspense } from '../hooks/suspense'
import GuestTableDetails from './GuestTableDetails';
import MTableFilterRow from './standard/MTableFilterRow/MTableFilterRow';
import MTableActionLoading from './standard/MTableActionLoading';
import StandardDialog from './standard/StandardDialog';
const PREFIX = 'SessionVideoView';

const classes = {
  video: `${PREFIX}-video`,
  videoControls: `${PREFIX}-videoControls`,
  videoButton: `${PREFIX}-videoButton`,
  videoData: `${PREFIX}-videoData`,
  clientViewers: `${PREFIX}-clientViewers`,
  popover: `${PREFIX}-popover`,
  sessions: `${PREFIX}-sessions`,
  textRow: `${PREFIX}-textRow`,
  messageRow: `${PREFIX}-messageRow`,
  label: `${PREFIX}-label`,
  selectField: `${PREFIX}-selectField`,
  titlegroup: `${PREFIX}-titlegroup`,
  tabBadge: `${PREFIX}-tabBadge`,
  dialog: `${PREFIX}-dialog`,
  actionRow: `${PREFIX}-actionRow`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.video}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.5rem'
  },
  [`& .${classes.videoControls}`]: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '1rem'
  },
  [`& .${classes.videoButton}`]: {
    margin: '6px',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  },
  [`& .${classes.videoData}`]: {
    flex: 1
  },
  [`& .${classes.clientViewers}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: 'max-content',
    padding: '0.5rem'
  },
  [`& .${classes.popover}`]: {
    pointerEvents: 'none',
  },
  // clientBadge: {
  //   marginLeft: '0.5rem',
  //   marginTop: 'auto',
  //   marginBottom: 'auto'
  // },
  [`& .${classes.sessions}`]: {
    marginBottom: '1rem',
    width: '100%'
  },
  [`& .${classes.textRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  [`& .${classes.titlegroup}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px'
  },
  [`& .${classes.dialog}`]: {
    width: '50%',
    minWidth: '500px'
  },
  [`& .${classes.actionRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
});

const MessageBoxRoot = styled('div')({
  [`& .${classes.messageRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.selectField}`]: {
    flexBasis: '80%',
  },
})

const TabsRoot = styled(Tabs)({
  [`& .MuiTabs-flexContainer`]: {
    justifyContent: 'space-between'
  },
  [`& .${classes.tabBadge}`]: {
    // position: 'absolute'
    paddingLeft: '16px',
    paddingBottom: '3px'
  },
})

// import moment from 'moment'

const cloneDeep = require('lodash/cloneDeep');

const formatTime = (time, noMs) => {
  const s = Math.floor(time) % 60
  const ms = !noMs && (time - Math.floor(time))
  const m = Math.floor(time / 60) % 60
  const h = Math.floor(time / 60 / 60)
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${ms ? (s + ms).toFixed(3).padStart(6, '0') : s.toString().padStart(2, '0')}`
}

// const tabs = [
//   'Not Arrived',
//   'Not Ready',
//   'Waiting',
//   'Watching',
//   'Survey',
//   'Complete',
//   'Disconnected'
// ]

const SessionVideoView = React.memo(({ sessionId, allowControl, allowPii, allowIp, focusGroup, surveyPlacement, hasZoomMeeting, enableGeoRestriction, clientViewers, connections, guests, disableChat, openChat, sendGuestMessage, ejectGuest, turnAwayGuest, uncheckInGuest, uncheckInLoading, forceRefreshGuest, moveGuestForward, moveGuestComplete, resetGuestConnection, startVideo, stopSession, setLiveStart, resetSession, fadeOutSound, disableFade, sessionStarted, currentStartTime, liveStarted, videoComplete, isLiveSession, multiSection, videoIndex, playlistLength }) => {
  // console.log('render video page')
  const [tableConnections, setTableConnections] = useState(undefined)

  const [tab, setTab] = useLocalStorage(`session${sessionId}videotab`, 'Not Arrived')
  const [messageBoxOpen, setMessageBoxOpen] = useState(false)
  const [messageGuest, setMessageGuest] = useState()
  const [messageRole, setMessageRole] = useState('ALL')
  const [messageStatus, setMessageStatus] = useState()
  const [ejectWarnOpen, setEjectWarnOpen] = useState(false)
  const [guestToEject, setGuestToEject] = useState(null)
  const [startWarnOpen, setStartWarnOpen] = useState(false)
  const [endWarnOpen, setEndWarnOpen] = useState(false);
  const [resetWarnOpen, setResetWarnOpen] = useState(false)
  const [tableFilters, setTableFilters] = useLocalStorage(`session${sessionId}videotablefilter`, {})
  const [tableSort, setTableSort] = useLocalStorage(`session${sessionId}videotablesort`, {})
  const [tableOrder, setTableOrder] = useLocalStorage(`session${sessionId}videotableorder`, {})
  // const [openRow, setOpenRow] = useLocalStorage(`session${sessionId}videoopenrow`, {})
  // const [currentFilter, setCurrentFilter] = useState([]);
  // const [clientPopoverAnchor, setClientPopoverAnchor] = useState();
  // const [clientViewFilter, setClientViewFilter] = useState();

  // const handleClientPopoverOpen = useCallback((event, filter) => {
  //   setClientViewFilter(filter)
  //   setClientPopoverAnchor(event.currentTarget);
  // }, []);

  // const handleClientPopoverClose = useCallback((event) => {
  //   setClientPopoverAnchor(null);
  // }, []);

  const clipboard = useClipboard({
    copiedTimeout: 1500
  });

  const copyLinkUrl = useCallback((ev, { viewerLinkUrl }) => {
    // ev.preventDefault();
    ev.stopPropagation();

    clipboard.copy(viewerLinkUrl);
    // if (!navigator.clipboard) return;
    // navigator.clipboard.writeText(ax).then(() => alert('Copied to clipboard')).catch((err) =>console.error('Failed to copy!', err))

  }, [clipboard])

  const clearVideoTableFilters = useCallback(() => {
    const currentTableFilers = [
      `session${sessionId}videotablefilter`,
      `session${sessionId}videotablesort`,
      `session${sessionId}videotableorder`
    ];
    clearLocalStorage(currentTableFilers);
    setTableFilters({});
    setTableSort({});
    setTableOrder({});
  }, [sessionId, setTableFilters, setTableSort, setTableOrder]);

  const messageText = useRef()
  const connectionsRef = useRef(tableConnections)
  const tableRef = useRef()
  // const openRef = useRef(openRow)

  const tabs = useMemo(() => {
    const tbs = ['Not Arrived']
    if (surveyPlacement && ['pre', 'both', 'all'].includes(surveyPlacement)) tbs.push('Pre-Survey')
    tbs.push(...(focusGroup ? ['Discussion'] : ['Not Ready', 'Waiting', 'Watching']))
    if (surveyPlacement && ['inter', 'all'].includes(surveyPlacement)) tbs.push('Inter-Survey')
    if (surveyPlacement && surveyPlacement !== 'pre') tbs.push('Post-Survey')
    tbs.push('Complete')
    if (hasZoomMeeting) tbs.push('Dropped')
    if (enableGeoRestriction) tbs.push('Blocked')
    tbs.push('Disconnected', 'Ejected')
    return tbs
  }, [focusGroup, surveyPlacement, hasZoomMeeting, enableGeoRestriction])

  // useEffect(() => {
  //   openRef.current = openRow
  // }, [openRow])

  useEffect(() => {
    // console.log('VIDEO CONNECTIONS: ', connections)
    if (connections && guests) {
      setTableConnections(connections ? cloneDeep(Object.values(connections)).filter(c => clientViewers ? !c.clientLinkId : true).map((c) => {
        const guest = guests.find(g => `${g.id}` === `${c.userId}`) || {}
        // if (guest.state === "Complete") c.userStatus = guest.role !== "Viewer" ? "Removed" : "Complete"
        if (!['Not Arrived', 'Ejected', 'Pre-Survey', 'Inter-Survey', 'Post-Survey', 'Complete', 'Dropped', 'Blocked', 'Removed'].includes(c.userStatus)) {
          if (c.disconnected) c.userStatus = "Disconnected"
        }
        if (c.viewerTime && c.connectionOffset) c.userTime = (c.viewerTime - c.connectionOffset)

        // OPEN PREVIOUSLY FOCUSED ROW (IF RELOADED)
        // if (tableRef.current) {
        //   const { detailPanel } = tableRef.current.props;
        //   let handleShowDetailPanel = detailPanel;

        //   if (typeof detailPanel === 'object') {
        //     handleShowDetailPanel = detailPanel[0].render
        //   }

        //   c.tableData = {
        //     showDetailPanel: openRef.current[c.userStatus] && (`${c.userId}` === `${openRef.current[c.userStatus].id}` && handleShowDetailPanel)
        //   }
        // }

        return { ...guest, ...c }
      }).reduce((r, v, i, a, k = v.userStatus) => ((r[k] || (r[k] = [])).push(cloneDeep(v)), r), {}) : undefined)
      // setTableConnections(cloneDeep(guests).map((g) => {
      //   const c = Object.values(connections).find(x => `${g.id}` === `${x.userId}`) || {userStatus: 'Not Arrived'}
      //   return { ...g, ...c }
      // }).filter(x => x.state === 'Checked-In').reduce((r, v, i, a, k = v.userStatus) => ((r[k] || (r[k] = [])).push(v), r), {}))
    }
  }, [connections, guests, clientViewers])

  useEffect(() => {
    // console.log(tableConnections)
    connectionsRef.current = tableConnections
  }, [tableConnections])

  const handleTabChange = useCallback((event, newTab) => {
    setTab(newTab);
  }, [setTab]);

  const openMessageDialog = useCallback((event, data, currentTab) => {
    setMessageGuest(data);
    setMessageStatus(currentTab);
    setMessageBoxOpen(true)
  }, []);

  const turnAwayNonInteractiveFG = useCallback(async (event, row) => {
    turnAwayGuest(event, row, true);
  }, [turnAwayGuest]);

  const handleMessageStatus = useCallback((event) => {
    setMessageStatus(event.target.value);
  }, []);

  const handleMessageRole = useCallback((event) => {
    setMessageRole(event.target.value);
  }, []);

  const sendMessage = useCallback((event, user, { message }, { status, role }) => {
    if (user) {
      sendGuestMessage(user, message)
    } else {
      sendGuestMessage({
        connectionId: 'ALL',
        audience: role || 'ALL',
        status: status || user.userStatus
      }, message)
    }
    setMessageBoxOpen(false)
    setMessageGuest(null);
    setMessageRole('ALL')
  }, [sendGuestMessage]);

  const openEjectWarning = useCallback((event, data) => {
    // console.log(data)
    setGuestToEject(data);
    setEjectWarnOpen(true)
  }, []);

  const openStartWarning = useCallback(() => {
    setStartWarnOpen(true)
  }, []);

  const openResetWarning = useCallback(() => {
    setResetWarnOpen(true)
  }, []);

  const openEndWarning = useCallback(() => {
    setEndWarnOpen(true);
  }, [])

  const tableColumns = useCallback(() => [
    ...(allowPii ? [
      { title: "First Name", field: "firstName", filtering: false },
      { title: "Last Name", field: "lastName", filtering: false }
    ] : []),
    { title: "Email", field: "email", filtering: false },
    { title: "Zoom Alias", field: "alias", filtering: false },
    {
      title: "Role", field: "role",
      lookup: {
        'Client': 'Client',
        'VIP': 'VIP',
        'Viewer': 'Viewer'
      },
      // defaultFilter: tableFilters[tab],
      // defaultSort: tableSort[tab] && tableSort[tab][4]
    },
    { title: "Status", field: "userSubstatus", filtering: false, hidden: (!['Not Ready', 'Blocked'].includes(tab)) },
    {
      title: "Potential Focus Group",
      field: "focusGroup",
      lookup: {
        'false': 'No',
        'true': 'Yes',
      },
      hidden: focusGroup
    },
    {
      title: "Selected for Focus Group",
      field: "selectedFocusGroup",
      lookup: {
        'false': 'No',
        'true': 'Yes',
      },
      hidden: focusGroup
    },
    { title: "Video Position", field: "currentIndex", filtering: false, hidden: (!(multiSection && ['Waiting', 'Watching'].includes(tab))), render: row => <span>{(row.currentIndex || 0) + 1}</span> },
    { title: "Video Time (+/- 1m)", field: "videoTime", filtering: false, hidden: (tab !== 'Watching'), render: row => <span>{formatTime(row.videoTime, true)}</span> },
    // { title: "User Time", field: "userTime", filtering: false, hidden: (tab !== 'Watching'), render: row => <span>{moment(row.serverTime).format('h:mm:ss a')}</span> },
    // { title: "Connection Offset", filtering: false, hidden: (!['Not Ready', 'Waiting', 'Watching'].includes(tab)), render: row => <span>{((row.viewerTime - row.serverTime)/1000).toFixed(2)}s</span> },
    {
      title: "Playback Latency",
      filtering: false,
      hidden: (tab !== 'Watching'),
      customSort: (a, b) => (currentStartTime ? ((a.videoTime * 1000) - a.serverTime) - ((b.videoTime * 1000) - b.serverTime) : 0),
      render: row => <span>{currentStartTime ? `${((Date.parse(currentStartTime) + (row.videoTime * 1000) - row.serverTime) / 1000).toFixed(2)}s` : '-'}</span>
    }
  ].map((x, i) => {
    x.columnIndex = (tableOrder[tab] && tableOrder[tab][x.field] !== undefined) ? tableOrder[tab][x.field] : i
    x.defaultSort = tableSort[tab] && tableSort[tab][x.field]
    if (x.filtering !== false) x.defaultFilter = tableFilters[tab] && tableFilters[tab][x.field]
    return x
  }).sort((a, b) => {
    return a.columnIndex - b.columnIndex;
  }), [focusGroup, allowPii, tab, tableSort, tableFilters, tableOrder, multiSection, currentStartTime])

  const disableStart = useMemo(() => {
    // return false
    return (tableConnections && Object.keys(tableConnections).length) ? Object.entries(tableConnections).filter(([k, v]) => ['Pre-Survey', 'Inter-Survey', 'Watching'].includes(k)).map(([k, v]) => !!v.filter(x => x.role === 'Viewer').length).reduce((p, c) => p || c, false) : true
  }, [tableConnections])

  const openUserChat = useCallback((event, guest) => openChat(guest), [openChat])

  const tableActions = useMemo(() => {
    const actions = []
    if (!['Complete', 'Ejected'].includes(tab)) actions.push(
      {
        icon: ClearAllIcon,
        tooltip: 'Clear All Filters',
        onClick: clearVideoTableFilters,
        isFreeAction: true,
      },
      (rowData) => {
        return {
          icon: AssignmentIcon,
          tooltip: clipboard.copied ? 'Link Copied to Clipboard' : 'Copy Link URL',
          onClick: copyLinkUrl,
          hidden: !rowData.viewerLinkUrl
        }
      }
    )
    // if (!['Pre-Survey', 'Post-Survey', 'Complete', 'Ejected'].includes(tab)) {
    if (['Not Ready', 'Waiting', 'Watching', 'Discussion'].includes(tab)) actions.push(
      {
        icon: ChatIcon,
        tooltip: 'Open Chat',
        onClick: openUserChat,
        disabled: disableChat
      },
      {
        icon: AnnouncementIcon,
        tooltip: 'Send Message',
        onClick: openMessageDialog,
        // hidden: ['Survey', 'Complete', 'Disconnected'].includes(tab)
      },
      {
        icon: AnnouncementIcon,
        tooltip: 'Send Group Message',
        onClick: (e) => openMessageDialog(e, null, tab),
        isFreeAction: true,
      }
    )
    if (['Watching', 'Discussion'].includes(tab)) actions.push(
      {
        icon: RefreshIcon, //SyncProblemIcon,
        tooltip: 'Force Refresh',
        onClick: forceRefreshGuest,
        // hidden: ['Survey', 'Complete', 'Disconnected'].includes(tab)
      },
      {
        icon: ArrowForwardIcon,
        tooltip: `Advance to ${(!!playlistLength && videoIndex >= playlistLength - 1) ? 'Next Video' : surveyPlacement && surveyPlacement !== 'pre' ? 'Post Survey' : 'Complete'}`,
        onClick: moveGuestForward,
        // hidden: ['Survey', 'Complete', 'Disconnected'].includes(tab)
      }
    )
    if (['Not Arrived', 'Pre-Survey', 'Disconnected'].includes(tab) || (tab === 'Complete' && (focusGroup && !surveyPlacement))) actions.push(
      (rowData) => {
        return {
          icon: 'undo',
          tooltip: 'Uncheck-In Guest',
          onClick: uncheckInGuest,
          isLoading: uncheckInLoading.has(rowData.id)
          // hidden: ['Survey', 'Complete', 'Disconnected'].includes(tab)
        }
      })
    if (!['Complete', 'Ejected'].includes(tab) || (tab === 'Complete' && (focusGroup && !surveyPlacement))) {
      actions.push(
        (rowData) => {
          return {
            icon: BlockIcon,
            tooltip: 'Eject from Session',
            onClick: openEjectWarning,
            disabled: rowData.role !== 'Viewer'
            // hidden: ['Survey', 'Complete'].includes(tab)
          }
        }
      )
      if (['Not Arrived', 'Not Ready', 'Dropped', 'Blocked', 'Disconnected'].includes(tab)) actions.push(
        {
          icon: BlockIcon,
          tooltip: 'Eject Group from Session',
          onClick: (e) => openEjectWarning(e, connectionsRef.current[tab]),
          isFreeAction: true,
        }
      )
    }
    if (allowControl && (['Not Ready', 'Waiting', 'Watching', 'Dropped'].includes(tab) || (tab === 'Discussion' && surveyPlacement))) actions.push(
      {
        icon: SyncDisabledIcon,
        tooltip: 'Reset Connection',
        onClick: resetGuestConnection,
      }
    )
    if (allowControl && ['Post-Survey', 'Disconnected'].includes(tab)) actions.push(
      {
        icon: ArrowForwardIcon,
        tooltip: `Move Guest to Complete`,
        onClick: moveGuestComplete,
        disabled: !videoComplete
      }
    )
    if ((tab === 'Complete' && (focusGroup && !surveyPlacement))) actions.push(
      (rowData) => {
        return {
          icon: PanToolRoundedIcon,
          tooltip: 'Turn Away Guest',
          onClick: turnAwayNonInteractiveFG,
        }
      }
    )
    // }
    return actions
  }, [tab, allowControl, surveyPlacement, playlistLength, uncheckInLoading, clearVideoTableFilters, clipboard, forceRefreshGuest, moveGuestForward, moveGuestComplete, videoIndex, videoComplete, openMessageDialog, openEjectWarning, uncheckInGuest, disableChat, openUserChat, copyLinkUrl, resetGuestConnection])

  const handleOrderChange = useCallback((i, order) => setTableSort(prev => {
    const field = tableColumns()[i]?.field
    return {
      ...prev, [tab]: field ? { [field]: order } : {}
    }
  }), [tableColumns, tab, setTableSort])

  const handleFilterChange = useCallback((e) => setTableFilters(prev => {
    const filters = {}
    e.forEach((f) => {
      filters[f.column.field] = f.value
    })
    return { ...prev, [tab]: filters }
  }), [tab, setTableFilters])

  const handleColumnDrag = useCallback((i, newIndex) => {
    const cols = [...tableColumns()]

    const x = cols[i]
    cols.splice(i, 1)
    cols.splice(newIndex, 0, x)

    setTableOrder(prev => {
      return {
        ...prev, [tab]: cols.reduce((p, c, ix) => {
          p[c.field] = ix
          return p
        }, {})
      }
    })
  }, [tableColumns, tab, setTableOrder])

  const messageBoxContent = useCallback(() => (
    !messageGuest && <MessageBoxRoot>
      <div className={classes.messageRow}>
        <Typography className={classes.label}>Role:</Typography>
        <Select
          value={messageRole}
          onChange={handleMessageRole}
          // displayEmpty
          className={classes.selectField}
        >
          <MenuItem value='ALL'>ALL</MenuItem>
          <MenuItem value='Viewer'>Viewer</MenuItem>
          <MenuItem value='Client'>Client</MenuItem>
          <MenuItem value='VIP'>VIP</MenuItem>
        </Select>
      </div>
      <div className={classes.messageRow}>
        <Typography className={classes.label}>Status:</Typography>
        <Select
          value={messageStatus}
          onChange={handleMessageStatus}
          // displayEmpty
          className={classes.selectField}
        >
          <MenuItem value='ALL'>ALL</MenuItem>
          <MenuItem value='Not Ready'>Not Ready</MenuItem>
          <MenuItem value='Waiting'>Waiting</MenuItem>
          <MenuItem value='Watching'>Watching</MenuItem>
        </Select>
      </div>
    </MessageBoxRoot>
  ), [classes, messageGuest, messageRole, messageStatus, handleMessageRole, handleMessageStatus])

  return (
    <Root>
      <div className={classes.video}>
        {/* <div className={classes.videoData}>
          {(clientViewers && tableConnections) && <>
            <Paper className={classes.clientViewers}>
              <Typography variant="button" style={{ fontSize: '70%' }}>Client Dial Viewers</Typography>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexBasis: '50%', flexDirection: 'column', alignItems: 'center' }} onMouseEnter={(e) => handleClientPopoverOpen(e, true)} onMouseLeave={handleClientPopoverClose}>
                  <Typography variant="body1" className={classes.clientBadge}>{(tableConnections && tableConnections['Client Dial Graph'] && tableConnections['Client Dial Graph'].length) || 0}</Typography>
                  <div style={{ fontSize: '55%', textTransform: 'uppercase', whiteSpace: 'nowrap' }}>SENT</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="body1" className={classes.clientBadge}>&nbsp;|&nbsp;</Typography>
                </div>
                <div style={{ display: 'flex', flexBasis: '50%', flexDirection: 'column', alignItems: 'center' }} onMouseEnter={(e) => handleClientPopoverOpen(e, false)} onMouseLeave={handleClientPopoverClose}>
                  <Typography variant="body1" className={classes.clientBadge}>{(tableConnections && tableConnections['Client Dial Graph'] && tableConnections['Client Dial Graph'].filter(x => !x.disconnected).length) || 0}</Typography>
                  <div style={{ fontSize: '55%', textTransform: 'uppercase', whiteSpace: 'nowrap' }}>WATCHING</div>
                </div>
              </div>
            </Paper>
            <Popover
              open={!!clientPopoverAnchor}
              anchorEl={clientPopoverAnchor}
              className={classes.popover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handleClientPopoverClose}
              disableRestoreFocus
            >
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Client Name</TableCell>
                      <TableCell>Client Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(tableConnections && tableConnections['Client Dial Graph'] && tableConnections['Client Dial Graph'].length) ?
                      tableConnections['Client Dial Graph'].filter(x => clientViewFilter || !x.disconnected).map((row) => (
                        <TableRow key={row.userId}>
                          {/* <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell> */}
        {/* <TableCell>{row.firstName} {row.lastName}</TableCell>
                          <TableCell>{row.email}</TableCell>
                        </TableRow>
                      )) : <TableRow key='emptyrow'>
                        <TableCell colSpan={2}>No Clients {clientViewFilter ? 'Sent Link' : 'Watching'}</TableCell>
                      </TableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
            </Popover>
          </>}
        </div> */}
        {allowControl && <div className={classes.videoControls}>
          {!focusGroup && <Button className={classes.videoButton} onClick={openStartWarning} variant="outlined" color="inherit" startIcon={<PlayArrowIcon />} disabled={multiSection ? (disableStart || (!!playlistLength && videoIndex >= playlistLength - 1)) : (sessionStarted || (!isLiveSession && disableStart))}>
            {isLiveSession ? 'START SESSION' : multiSection ? sessionStarted ? 'CONTINUE PLAYLIST' : 'START PLAYLIST' : 'START VIDEO'}
          </Button>}
          {isLiveSession && <Button className={classes.videoButton} onClick={setLiveStart} variant="outlined" color="inherit" startIcon={<PlayArrowIcon />} disabled={!sessionStarted || liveStarted || videoComplete}>
            RESET DIAL START POINT
          </Button>}
          {!isLiveSession && <Button className={classes.videoButton} onClick={openResetWarning} variant="outlined" color="inherit" startIcon={<ReplayIcon />} disabled={!sessionStarted || videoComplete}>
            RESET SESSION
          </Button>}
          {/* <Button className={classes.videoButton} onClick={fadeOutSound} variant="outlined" color="inherit" startIcon={<VolumeDownIcon />} disabled={(!sessionStarted || videoComplete) && disableFade}>
            FADE OUT SOUND
          </Button> */}
          {(focusGroup || isLiveSession) && <Button className={classes.videoButton} onClick={openEndWarning} variant="outlined" color="inherit" startIcon={<StopIcon />} disabled={(!sessionStarted && !focusGroup) || videoComplete}>
            {isLiveSession ? 'STOP SESSION' : focusGroup ? 'END FOCUS GROUP' : 'STOP VIDEO'} {/* surveyPlacement && surveyPlacement !== 'pre' ? 'START POST-SURVEY' : */}
          </Button>}
        </div>}
        {/* <div className={classes.videoData}></div> */}
      </div>
      <AppBar position="relative" color="lightgrey" elevation={2}>
        <TabsRoot
          value={tab}
          key={`session${sessionId}tabs`}
          onChange={handleTabChange}
          // variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs && tabs.map((tb) => (
            <Tab key={`tab-${tb}`} label={
              <div>
                {tb}
                <Badge color="secondary" max={999} badgeContent={(tableConnections && tableConnections[tb] && tableConnections[tb].length) || '0'}>
                  <div className={classes.tabBadge} />
                </Badge>
              </div>
            } value={tb} />
          ))}
        </TabsRoot>
      </AppBar>
      <MaterialTable
        tableRef={tableRef}
        onOrderCollectionChange={handleOrderChange}
        onFilterChange={handleFilterChange}
        onColumnDragged={handleColumnDrag}
        key={`session${sessionId}videotable${tab}`}
        options={{
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          detailPanelType: 'single',
          // toolbarButtonAlignment: 'left',
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 75, 100]
        }}
        columns={tableColumns() || []}
        // columns={[
        //   { title: "First Name", field: "firstName", filtering: false, defaultSort: tableSort[tab] && tableSort[tab][0] },
        //   { title: "Last Name", field: "lastName", filtering: false, defaultSort: tableSort[tab] && tableSort[tab][1] },
        //   { title: "Email", field: "email", filtering: false, defaultSort: tableSort[tab] && tableSort[tab][2] },
        //   { title: "Zoom Alias", field: "alias", filtering: false, defaultSort: tableSort[tab] && tableSort[tab][3] },
        //   {
        //     title: "Role", field: "role",
        //     lookup: {
        //       'Client': 'Client',
        //       'VIP': 'VIP',
        //       'Viewer': 'Viewer'
        //     },
        //     defaultFilter: tableFilters[tab],
        //     defaultSort: tableSort[tab] && tableSort[tab][4]
        //   },
        //   { title: "Status", field: "userSubstatus", filtering: false, hidden: (tab !== 'Not Ready'), defaultSort: tableSort[tab] && tableSort[tab][5] }
        // ]}
        isLoading={!(guests && connections && tableConnections)}
        // data={(!!(tableConnections) && tableConnections[tab]) || undefined} //MATERIAL TABLE MUTATES PROPS :(
        data={(!!(tableConnections && tableConnections[tab]) && tableConnections[tab].map(x => x)) || []} //MATERIAL TABLE MUTATES PROPS :(
        title={tab}
        actions={tableActions}
        components={{
          Action: props => {
            // const action = typeof props.action === "function" ? props.action(props.data) : props.action
            return (
              // <div className={classes.action}>
              //   <Typography>{JSON.stringify(typeof props.action)}</Typography>
              //   {action.isLoading && <CircularProgress size={48} className={classes.actionLoading} />}
              //   <MTableAction {...props} />
              // </div>
              <MTableActionLoading {...props} />
            )
          },
          Actions: props => {
            return (
              <div className={classes.actionRow}><MTableActions {...props} /></div>
            )
          },
          FilterRow: (props) => {
            return (
              <MTableFilterRow {...props} />
            )
          },
          // Row: props => {
          //   return (
          //     <MTableBodyRow {...props}
          //       onRowClick={(e, row, toggleDetailPanel) => {
          //         props.onToggleDetailPanel(props.path, typeof props.detailPanel === 'object' ? props.detailPanel[0].render : props.detailPanel)
          //         e.stopPropagation();
          //         // openRef.current = openRef.current.id === row.id ? {} : row
          //         setOpenRow(prev => {
          //           const selected = (prev[tab] && prev[tab].id === row.id) ? {} : { id: row.id }
          //           return { ...prev, [tab]: selected }
          //         })
          //       }} />
          //   )
          // }
        }}
        detailPanel={[{
          // disabled: true,
          render: ({ rowData }) => <GuestTableDetails allowPii={allowPii} allowIp={allowIp} {...rowData} />
        }]}
      />
      <StandardDialog
        open={messageBoxOpen}
        title={messageGuest ? `Send Message to ${messageGuest.firstName} ${messageGuest.lastName}` : `Send Group Message`}
        content={messageBoxContent}
        fieldOptions={[
          {
            label: 'Chat Message',
            key: 'message',
            type: 'text',
            multiline: true,
            required: true
          }
        ]}
        submitLabel="Send"
        onSubmit={(e, t) => {
          sendMessage(e, messageGuest, t, { status: messageStatus, role: messageRole })
        }}
        // cancelLabel="NO"
        onCancel={() => {
          setMessageBoxOpen(false)
        }}
      />
      <StandardDialog
        open={startWarnOpen}
        title="Start Session Content?"
        content="Are you sure you want to start the session content?"
        submitLabel="YES"
        onSubmit={(e) => {
          startVideo()
          setStartWarnOpen(false)
        }}
        cancelLabel="NO"
        onCancel={() => {
          setStartWarnOpen(false)
        }}
      />
      <StandardDialog
        open={endWarnOpen}
        title={focusGroup ? 'End Focus Group' : 'End Session Content'}
        content={`Are you sure you want to end the ${focusGroup ? 'Focus Group' : 'Session Content'}?`}
        submitLabel="YES"
        onSubmit={(e) => {
          stopSession();
          setEndWarnOpen(false)
        }}
        cancelLabel="NO"
        onCancel={() => {
          setEndWarnOpen(false)
        }}
      />
      <StandardDialog
        open={resetWarnOpen}
        title="Reset Session Content?"
        content="Are you sure you want to reset the session content?"
        submitLabel="YES"
        onSubmit={(e) => {
          resetSession()
          setResetWarnOpen(false)
        }}
        cancelLabel="NO"
        onCancel={() => {
          setResetWarnOpen(false)
        }}
      />
      <StandardDialog
        open={ejectWarnOpen}
        title="Check-In Ejected Guest?"
        content={guestToEject && (!Array.isArray(guestToEject) ? `Are you sure you want to eject ${guestToEject.firstName} ${guestToEject.lastName} from this session?` : `Are you sure you want to eject all guests who are currently "${tab}" from this session?`)}
        fieldOptions={[
          {
            label: 'Eject Reason',
            key: 'ejectReason',
            type: 'text',
            multiline: true,
            required: true
          },
          {
            label: 'Do Not Pay',
            key: 'doNotPay',
            type: 'boolean'
          },
          {
            label: 'Ban Guest From VSR',
            key: 'banned',
            type: 'boolean'
          },
          {
            label: 'Banned Reason',
            key: 'banReason',
            type: 'text',
            multiline: true,
            required: true,
            condition: {
              key: 'banned',
              value: true
            }
          },
        ]}
        submitLabel="YES"
        onSubmit={(e, t) => {
          ejectGuest(e, Array.isArray(guestToEject) ? guestToEject : [guestToEject], t)
          setEjectWarnOpen(false)
          setGuestToEject(null)
        }}
        cancelLabel="NO"
        onCancel={() => {
          setEjectWarnOpen(false)
          setGuestToEject(null)
        }}
      />
    </Root>
  );
})

export default withSuspense(SessionVideoView)