import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { sessionRewards, sessionGuestRewards, exchangeRates } from '../recoil/atoms'
import { useLocalStorage } from '../hooks/hooks'
import { Button, CircularProgress, IconButton, Modal, Tooltip, Typography } from '@mui/material';
//add icons
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import MaterialTable, { MTableActions } from "@material-table/core"
import MTableActionLoading from './standard/MTableActionLoading';
import MTableCell from './standard/MTableCell';
import MTableFilterRow from './standard/MTableFilterRow/MTableFilterRow';
// import MTableGroupbar from './standard/MTableGroupbar';
import MTableGroupRow from './standard/MTableGroupRow';
import { MTableGroupbar } from '@material-table/core';
import { CsvBuilder } from 'filefy'
import SessionRewardsModal from './SessionRewardsModal';
import SessionRewardsSubscription from '../recoil/SessionRewardsSubscription'
// import { MTableToolbar } from './standard/MTableToolbar';
import { difference } from './standard/utils'

import currenciesJSON from '../utils/iso-4217.json'
import GuestRewardModal from './GuestRewardModal';
import StandardDialog from './standard/StandardDialog';

const PREFIX = 'SessionRewardsView';

const classes = {
  action: `${PREFIX}-action`,
  actionLoading: `${PREFIX}-actionLoading`,
  actionRow: `${PREFIX}-actionRow`,
  sessionRewardBtn: `${PREFIX}-sessionRewardBtn`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.action}`]: {
    position: 'relative',
    marginRight: '5px'
  },
  [`& .${classes.actionLoading}`]: {
    // color: green[500],
    position: 'absolute',
    // top: -6,
    // left: -6,
    zIndex: 1,
  },
  [`& .${classes.actionRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  [`& .${classes.sessionRewardBtn}`]: {
    margin: '200px 6px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  }
});

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');
const reduce = require('lodash/reduce')

const keyify = (obj, prefix = '') => {
  return Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res;
    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], prefix + el + '.')];
    }
    return [...res, prefix + el];
  }, []);
}

const currencySymbols = currenciesJSON.reduce((p, c) => (p[c.code] = c.symbol, p), {})

// const usdFormat = (amount) => (Math.round(amount * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2)

const baseCurrencyFormat = (amount, decimals = 2) => (Math.round(amount * Math.pow(10, decimals)))

const standardCurrencyFormat = (baseCurrencyAmount, decimals = 2) => isNaN(baseCurrencyAmount) ? `\u2014.${`\u2014`.repeat(decimals)}` : (baseCurrencyAmount / Math.pow(10, decimals)).toFixed(decimals)

const totalByGroup = (guests, groupKeys, rates) => {
  const guestGroups = {}
  return guests.reduce((p, c) => {
    const [groupKey, ...groups] = groupKeys
    guestGroups[c[groupKey]] = [...(guestGroups[c[groupKey]] || []), c]
    // if (!p[groupKey]) p[groupKey] = {}
    p[c[groupKey]] = {
      total: ((p[c[groupKey]] && p[c[groupKey]].total) || 0) + (baseCurrencyFormat(c.reward.amount || 0) * (rates[c.reward.currency] || 1))
    }
    if (groups.length) {
      p[c[groupKey]] = {
        ...p[c[groupKey]],
        ...totalByGroup(guestGroups[c[groupKey]], groups, rates)
      }
    }
    return p
  }, {})
}

// const renderGroupColumn = (row, type, column, total) => {
//   console.log('rendering group column', row, type)
//   return <div style={{ display: 'flex', flex: 1, alignItems: 'center', paddingLeft: '0.5em' }}>
//     {typeof row === 'object' ? row[column] : <>
//       <div>{row}</div>
//       <div style={{ fontWeight: 'bold', marginLeft: 'auto', paddingRight: '2rem' }}>TOTAL: ${standardCurrencyFormat(total?.[column]?.[row])}</div>
//     </>}
//   </div>
// }

const renderGroupRow = (label, total) => (
  <div style={{ display: 'flex', flex: 1, alignItems: 'center', paddingLeft: '0.5em' }}>
    <div>{label}</div>
    <div style={{ fontWeight: 'bold', marginLeft: 'auto', paddingRight: '2rem' }}>TOTAL: ${total}</div>
  </div>
)

const SessionRewardsView = React.memo(({
  // loading,
  update,
  sessionId,
  guests,
  segments,
  getTangoCustomers,
  getTangoCatalogs,
  getTangoEmailTemplates,
  submitSessionRewards,
  submitGuestReward,
  submitRewardsOrder,
  resendGuestReward,
  resendLoading
}) => {
  // console.log('render rewards view', sessionId)
  const rewards = useRecoilValue(sessionRewards(sessionId));
  const guestRewards = useRecoilValue(sessionGuestRewards(sessionId));
  const rates = useRecoilValue(exchangeRates);
  // const session = useRecoilValue(selectedSession);
  // const guests = useRecoilValue(guestsForSession(sessionId));
  // const user = useRecoilValue(currentUser);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tableGuests, setTableGuests] = useState(undefined)
  const [guestCountries, setGuestCountries] = useState(new Set())
  const [guestRecruiters, setGuestRecruiters] = useState(new Set())
  const [tableGroups, setTableGroups] = useState([])
  // const [groupTotals, setGroupTotals] = useState({})
  const [sessionRewardsModalOpen, setSessionRewardsModalOpen] = useState(false)
  const [guestRewardModalOpen, setGuestRewardModalOpen] = useState(false)
  const [submitWarnOpen, setSubmitWarnOpen] = useState(false)
  const [selectedGuestReward, setSelectedGuestReward] = useState(null)
  const [resendWarnOpen, setResendWarnOpen] = useState(false)
  const [tableFilters, setTableFilters] = useLocalStorage(`session${sessionId}rewardstablefilter`, {})
  const [tableSort, setTableSort] = useLocalStorage(`session${sessionId}rewardstablesort`, {})
  const [tableOrder, setTableOrder] = useLocalStorage(`session${sessionId}rewardstableorder`, {})
  const [tableHidden, setTableHidden] = useLocalStorage(`session${sessionId}rewardstablehidden`, {})
  // const [openRow, setOpenRow] = useLocalStorage(`session${sessionId}rewardsopenrow`, {})
  // const { oktaAuth } = useOktaAuth();

  // const [tab, setTab] = useState(selectedView || 'guests');
  // const [loading, setLoading] = useState(false);

  const tableRef = useRef()
  // const openRef = useRef(openRow)
  const tableGroupsRef = useRef([])

  // useEffect(() => {
  //   openRef.current = openRow
  // }, [openRow])

  useEffect(() => {
    const countries = new Set()
    const recruiters = new Set()
    const parsedGuests = guests ? guests.map(o => {
      const reward = (guestRewards && guestRewards.find(r => `${o.id}` === `${r.guestId}`)) || {}
      const g = { ...o, ...reward }

      countries.add(g.country)
      recruiters.add(g.recruiterCode || null)

      // FOR PROPER GROUP RENDERING
      g.focusGroupGroup = g.selectedFocusGroup ? 'YES' : 'NO'
      g.recruiterCodeGroup = g.recruiterCode || 'N/A'
      g.paidGroup = g.paid ? 'YES' : 'NO'
      g.excludedGroup = g.excluded ? 'YES' : 'NO'
      // g.selectedFocusGroup = g.selectedFocusGroup ? 'YES' : 'NO'
      // g.recruiterCode = g.recruiterCode || 'N/A'
      // g.excluded = g.excluded ? 'YES' : 'NO'
      // g.paid = g.paid ? 'YES' : 'NO'

      // OPEN PREVIOUSLY FOCUSED ROW (IF RELOADED)
      // if (tableRef.current) {
      //   const { detailPanel } = tableRef.current.props;
      //   let handleShowDetailPanel = detailPanel;

      //   if (typeof detailPanel === 'object') {
      //     handleShowDetailPanel = detailPanel[0].render
      //   }

      //   g.tableData = {
      //     showDetailPanel: g.id === openRef.current.id && handleShowDetailPanel
      //   }
      // }
      return g
    }).filter(x => x.sessionRewardsId && !x.excluded) : undefined
    setTableGuests(guestRewards ? parsedGuests : undefined);
    setGuestCountries(countries)
    setGuestRecruiters(recruiters)
  }, [guests, guestRewards]);

  const paymentTypes = useMemo(() => {
    return (rewards && rewards.rewardsConfig && Object.values(rewards.rewardsConfig).filter(x => x.paymentType).map(({ paymentType }) => paymentType)) || []
  }, [rewards])

  const rewardsTotal = useMemo(() => {
    return standardCurrencyFormat((rates && tableGuests) ? tableGuests.reduce((p, c) => (p += baseCurrencyFormat(c.reward.amount || 0) * (rates[c.reward.currency] || 1), p), 0) : undefined)
  }, [rates, tableGuests])

  const rewardsSubtotals = useMemo(() => {
    const x = (rates && tableGuests) && tableGuests.reduce((p, c) => {
      p[c.reward.paymentTypeId] = (p[c.reward.paymentTypeId] || 0) + baseCurrencyFormat(c.reward.amount || 0)
      return p
    }, {})
    return (rates && tableGuests) ? tableGuests.reduce((p, c) => (p[c.reward.paymentTypeId] = (p[c.reward.paymentTypeId] || 0) + baseCurrencyFormat(c.reward.amount || 0), p), {}) : {}
  }, [rates, tableGuests])

  const handleGroupChange = useCallback((newGroups) => {
    const newTableGroups = newGroups.map(x => x.field)
    if (!isEqual(newTableGroups, tableGroupsRef.current)) {
      // console.log('GROUP CHANGE', newTableGroups)
      tableGroupsRef.current = newTableGroups
      setTableGroups(newTableGroups)
    }
  }, [])

  const groupTotals = useMemo(() => {
    return (rates && tableGuests && tableGroups.length) ? totalByGroup(tableGuests, tableGroups, rates) : {}
  }, [rates, tableGuests, tableGroups])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: "First Name",
        field: "firstName",
        filtering: false,
        grouping: false,
      },
      {
        title: "Last Name",
        field: "lastName",
        filtering: false,
        grouping: false,
      },
      {
        title: "Email",
        field: "email",
        filtering: false,
        grouping: false,
      },
      {
        title: "State",
        field: "state",
        // render: (row) => renderGroupColumn(row, 'state')
      },
      {
        title: "Focus Group",
        field: "focusGroupGroup",
        // render: (row) => renderGroupColumn(row, 'focusGroupGroup')
      },
      {
        title: "Recruiter Code",
        field: "recruiterCodeGroup",
        // render: (row) => renderGroupColumn(row, 'recruiterCodeGroup')
      },
      ...segments.map(segment => (segment.matrix && segment.matrixKeys) ? segment.matrixKeys.map(mk => ({ ...segment, matrixKey: mk.option })) : segment).flat().map((segment, i) => {
        // ((segment.matrix && segment.matrixKeys) || []).map()
        const colTitle = segment.matrixKey ? `${segment.questionKey} - ${segment.matrixKey}` : segment.questionKey
        const colKey = segment.matrixKey ? `segments.${segment.questionKey}.${segment.matrixKey}` : `segments.${segment.questionKey}`
        const segcol = {
          title: colTitle,
          field: colKey,
          grouping: false,
        }
        if (!segment.multiple && !segment.freeNumeric) {
          segcol.lookup = {}
          segment.answers.forEach(answer => segcol.lookup[answer.answer] = answer.answer)
        } else if (segment.multiple) {
          segcol.render = row => <>{row.segments ? Array.isArray(row.segments[segment.questionKey]) ? row.segments[segment.questionKey].join(', ') : row.segments[segment.questionKey] : ''}</>
        }
        return segcol
      }),
      {
        title: "Reward Amount",
        field: "reward",
        grouping: false,
        render: (row) => `${currencySymbols[row.reward.currency]}${row.excluded ? 0 : row.reward.amount}`,
        customSort: (a, b) => {
          return (a.excluded ? 0 : a.reward.amount) - (b.excluded ? 0 : b.reward.amount)
        },
      },
      {
        title: "Paid",
        field: "paidGroup",
        // render: (row) => renderGroupColumn(row, 'paidGroup')
      }
    ]
    return columns.map((x, i) => {
      x.columnIndex = tableOrder[x.field] !== undefined ? tableOrder[x.field] : i
      x.defaultSort = tableSort[x.field]
      x.hidden = typeof tableHidden[x.field] === 'boolean' ? tableHidden[x.field] : x.field.includes('segments.')
      if (x.filtering !== false) x.defaultFilter = tableFilters[x.field]
      return x
    }).sort((a, b) => {
      return a.columnIndex - b.columnIndex;
    })
  }, [tableFilters, tableSort, tableOrder, tableHidden, segments])

  const exportCSV = useCallback((exportData) => {
    const data = cloneDeep(exportData)
    const segs = new Set()
    const headers = reduce(data, (result, value, key) => {
      const exportedColumns = [
        'id','sessionId','guestId','firstName','lastName','email','state','focusGroup','recruiterCode','segments','reward','adusted','paid','tangoPaymentId'
      ]
      for (let i in value) {
        if (!exportedColumns.includes(i)) delete value[i]
      }
      // copy segments to individual columns
      if (!!value.segments) {
        Object.keys(value.segments).forEach(segment => {
          if (!!value.segments[segment] && typeof value.segments[segment] === 'object' && !Array.isArray(value.segments[segment])) {
            Object.keys(value.segments[segment]).forEach(optionKey => {
              const option = `${segment} - ${optionKey}`
              value[option] = value.segments[segment][optionKey]
              segs.add(option)
            })
          } else {
            value[segment] = Array.isArray(value.segments[segment]) ? value.segments[segment].join(', ') : value.segments[segment]
            segs.add(segment)
          }
        })
      }
      delete value.segments
      // copy rewards to individual columns
      if (!!value.reward) {
        // Object.keys(value.reward).forEach(reward => {
        //   value[`reward ${reward}`] = value.reward[reward]
        // })
        value['rewardAmount'] = value.reward.amount
        value['rewardCurrency'] = value.reward.currency
        value['rewardPaymentTypeId'] = value.reward.paymentTypeId
      }
      delete value.reward
      keyify(value).forEach(v => {
        result.add(v)
      })
      return result;
    }, new Set());
    const kx = [...headers]
    const columns = [...headers].map(v => segs.has(v) ? v : v.charAt(0).toUpperCase() + v.slice(1).replace(/([A-Z])/g, ' $1'))
    const builder = new CsvBuilder(`Session ${sessionId} Guest Rewards List.csv`);
    builder
      .setColumns(columns)
      .addRows(data.map((rowData) => {
        return kx.map(col => rowData[col])
      }))
      .exportFile();
  }, [sessionId])

  const handleOrderChange = useCallback((i, order) => setTableSort(() => {
    const field = tableColumns[i]?.field
    return field ? { [field]: order } : {}
  }), [tableColumns, setTableSort])

  const handleFilterChange = useCallback((e) => setTableFilters(prev => {
    const filters = {}
    e.forEach((f) => {
      filters[f.column.field] = f.value
    })
    return filters
  }), [setTableFilters])

  const handleColumnDrag = useCallback((i, newIndex) => {
    const cols = [...tableColumns].filter(x => !x.hidden)

    const x = cols[i]
    cols.splice(i, 1)
    cols.splice(newIndex, 0, x)

    setTableOrder(cols.reduce((p, c, ix) => {
      p[c.field] = ix
      return p
    }, {}))
  }, [tableColumns, setTableOrder])

  const handleHideColumn = useCallback(({ field, hidden }) => {
    setTableHidden(prev => {
      const hiddenFields = { ...prev }
      hiddenFields[field] = hidden
      return hiddenFields
    })
  }, [setTableHidden])

  const rewardsLoaded = useCallback(() => {
    setLoading(false)
  }, [])

  const handleOpenSessionRewardsModal = useCallback(() => {
    setSessionRewardsModalOpen(true);
  }, []);

  const handleCloseSessionRewardsModal = useCallback(() => {
    setSessionRewardsModalOpen(false);
  }, []);

  const editGuestReward = useCallback((event, data) => {
    setSelectedGuestReward(data);
    setGuestRewardModalOpen(true);
  }, [])

  const handleCloseGuestRewardModal = useCallback(() => {
    setSelectedGuestReward(null);
    setGuestRewardModalOpen(false);
  }, []);

  const handleRewardsSubmit = useCallback((event, submitData) => {
    setSessionRewardsModalOpen(false)
    setLoading(true)
    const data = difference(rewards, submitData)
    // console.log('REWARDS SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {
      if (rewards.id) {
        data.id = rewards.id
      }
      data.sessionId = sessionId
      for (let i in data) {
        if (typeof data[i] !== 'boolean' && !data[i]) delete data[i]
      }
      if (data.tableData) delete data.tableData
      if (data.rewardsConfig) {
        data.rewardsConfig = JSON.stringify(data.rewardsConfig) // FOR MYSQL DATA API
      }
      submitSessionRewards(data)
    } else {
      setLoading(false)
      submitSessionRewards()
    }
  }, [rewards, sessionId, submitSessionRewards])

  const handleGuestRewardSubmit = useCallback((event, submitData) => {
    setGuestRewardModalOpen(false)
    setLoading(true)
    const data = difference(selectedGuestReward, submitData)
    // console.log('GUEST REWARD SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {
      data.id = selectedGuestReward.id
      data.sessionRewardsId = rewards.id
      data.sessionId = sessionId
      for (let i in data) {
        if (typeof data[i] !== 'boolean' && !data[i]) delete data[i]
      }
      if (data.tableData) delete data.tableData
      if (data.reward) {
        data.reward = JSON.stringify(data.reward) // FOR MYSQL DATA API
      }
      submitGuestReward(data)
    } else {
      setLoading(false)
      submitGuestReward()
    }
  }, [rewards, sessionId, selectedGuestReward, submitGuestReward])

  const openSubmitWarning = useCallback(() => {
    setSubmitWarnOpen(true)
  }, []);

  const openResendWarning = useCallback((event, data) => {
    setSelectedGuestReward(data);
    setResendWarnOpen(true)
  }, []);

  const handleSubmitRewardsOrder = useCallback(() => {
    setSubmitLoading(true)
    submitRewardsOrder(rewards.id).then(() => setSubmitLoading(false))
  }, [rewards, submitRewardsOrder]);

  const handleResendGuestReward = useCallback((data) => {
    resendGuestReward(data.id)
  }, [resendGuestReward]);

  return (<Root>
    <SessionRewardsSubscription refresh={update} sessionId={sessionId} onLoaded={rewardsLoaded} key={`sessionrewards${sessionId}subscription`} />
    {sessionRewardsModalOpen && <SessionRewardsModal data={cloneDeep(rewards)} onSubmit={handleRewardsSubmit} open={sessionRewardsModalOpen} handleClose={handleCloseSessionRewardsModal} getTangoCustomers={getTangoCustomers} getTangoCatalogs={getTangoCatalogs} getTangoEmailTemplates={getTangoEmailTemplates} countryOptions={guestCountries} recruiterCodes={guestRecruiters} />}
    {guestRewardModalOpen && <GuestRewardModal data={cloneDeep(selectedGuestReward)} getTangoCatalogs={getTangoCatalogs} allowedTangoCatalogs={paymentTypes.map(x => x.id)} currencySymbols={currencySymbols} onSubmit={handleGuestRewardSubmit} open={guestRewardModalOpen} handleClose={handleCloseGuestRewardModal} />}
    {!rewards?.id ? <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      {(!rewards || loading) ? <CircularProgress /> : <Button className={classes.sessionRewardBtn} onClick={handleOpenSessionRewardsModal} variant="outlined" color="inherit">
        CONFIGURE SESSION REWARDS
      </Button>}
    </div> : <div>
      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ padding: '6px', textAlign: 'center' }}>
            <Typography variant="h1">${rewardsTotal}</Typography>
            <Typography variant="subtitle1">REWARDS TOTAL</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {paymentTypes.map((paymentType) => (<div key={paymentType.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ padding: '6px' }}>
                <Typography variant="h3">{currencySymbols[paymentType.currency]}{standardCurrencyFormat(rewardsSubtotals[paymentType.id])}</Typography>
              </div>
              <div>
                <Typography variant="body1">{paymentType.name}</Typography>
                <Typography variant="overline">{paymentType.id}</Typography>
              </div>
            </div>))}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button style={{ margin: '4px', border: '1px solid rgba(0, 0, 0, 0.23)' }} onClick={handleOpenSessionRewardsModal} variant="outlined" color="inherit" startIcon={<EditIcon />}>
            EDIT SESSION REWARDS
          </Button>
          <Button style={{ margin: '4px', border: '1px solid rgba(0, 0, 0, 0.23)' }} onClick={openSubmitWarning} variant="outlined" color="inherit" disabled={submitLoading} startIcon={submitLoading ? <CircularProgress size={'1rem'} /> : <SendIcon />}>
            {rewards.submitted ? 'SUBMIT UNPAID REWARDS' : 'SUBMIT REWARDS ORDER'}
          </Button>
        </div>
      </div>
      <MaterialTable
        key={`session${sessionId}rewardstable`}
        tableRef={tableRef}
        onOrderCollectionChange={handleOrderChange}
        onFilterChange={handleFilterChange}
        onColumnDragged={handleColumnDrag}
        onChangeColumnHidden={handleHideColumn}
        options={{
          emptyRowsWhenPaging: false,
          // detailPanelType: 'single',
          // rowStyle: rowData => ({
          //   backgroundColor: ['Pending', 'Denied'].includes(rowData.industryCheck) ? rowData.industryCheck === 'Denied' ? '#fde2e0' : '#e2e0fd' : '#fff'
          // }),
          columnsButton: true,
          // filtering: true,
          grouping: true,
          // groupTitle: () => 'HELLO WORLD',
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 75, 100]
        }}
        columns={tableColumns || []}
        isLoading={!(guestRewards && tableGuests) || loading}
        data={tableGuests || []} //MATERIAL TABLE MUTATES PROPS :(
        title="Rewards"
        actions={[
          {
            icon: 'download',
            tooltip: 'Export Rewards CSV',
            onClick: () => exportCSV(cloneDeep(tableGuests)),
            isFreeAction: true,
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit Guest Reward',
            onClick: editGuestReward,
            disabled: rowData.tangoPaymentId
          }),
          (rowData) => ({
            icon: SendIcon,
            tooltip: 'Resend Guest Reward',
            onClick: openResendWarning,
            isLoading: resendLoading.has(rowData.id),
            disabled: !rowData.tangoPaymentId
          }),
        ]}
        components={{
          Action: props => (
            <MTableActionLoading {...props} />
          ),
          Actions: props => (
            <div className={classes.actionRow}><MTableActions {...props} /></div>
          ),
          FilterRow: (props) => {
            return (
                <MTableFilterRow {...props} />
            )
          },
          // Row: props => {
          //   return (
          //     <MTableBodyRow {...props}
          //       onRowClick={(e, row, toggleDetailPanel) => {
          //         props.onToggleDetailPanel(props.path, typeof props.detailPanel === 'object' ? props.detailPanel[0].render : props.detailPanel)
          //         e.stopPropagation();
          //         // openRef.current = openRef.current.id === row.id ? {} : row
          //         setOpenRow(prev => prev.id === row.id ? {} : { id: row.id })
          //       }}
          //     />
          //   )
          // },
          GroupRow: props => {
            const groupTotal = standardCurrencyFormat(props.groupData.path.reduce((p, c) => p[c], groupTotals)?.total)
            return <MTableGroupRow {...props} render={() => renderGroupRow(props.groupData.value, groupTotal)} />
          },
          Groupbar: props => (
            <MTableGroupbar {...props} onGroupChange={handleGroupChange} />
          ),
          Cell: props => (
            <MTableCell {...props} />
          )
          // Toolbar: props => <MTableToolbar {...props} />
        }}
      // detailPanel={[{
      //   disabled: true,
      //   render: ({ rowData }) => <GuestTableDetails allowPii={allowPii} allowIp={allowIp} {...rowData} />
      // }]}
      />
      <StandardDialog
        open={submitWarnOpen}
        title={`${rewards.submitted ? 'Resubmit' : 'Submit'} Session Rewards Order?`}
        content={`Are you sure you want to ${rewards.submitted ? 'resubmit' : 'submit'} this session rewards order for processing?`}
        submitLabel="YES"
        onSubmit={(e) => {
          handleSubmitRewardsOrder()
          setSubmitWarnOpen(false)
          setSelectedGuestReward(null)
        }}
        cancelLabel="NO"
        onCancel={() => {
          setSubmitWarnOpen(false)
          setSelectedGuestReward(null)
        }}
      />
      <StandardDialog
        open={resendWarnOpen}
        title="Resend Guest Reward?"
        content={selectedGuestReward && `Are you sure you want to resend this reward to ${selectedGuestReward.firstName} ${selectedGuestReward.lastName}?`}
        submitLabel="YES"
        onSubmit={(e) => {
          handleResendGuestReward(selectedGuestReward)
          setResendWarnOpen(false)
          setSelectedGuestReward(null)
        }}
        cancelLabel="YES"
        onCancel={() => {
          setSubmitWarnOpen(false)
          setResendWarnOpen(null)
        }}
      />
    </div>}
  </Root>);
})

export default SessionRewardsView //withSuspense(SessionRewardsView)