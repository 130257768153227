import { atom, atomFamily } from 'recoil';

export const currentUser = atom({
    key: 'currentUser',
    default: null
})

export const sessionState = atom({
    key: 'sessionState',
    default: null
})

export const selectedSession = atom({
    key: 'selectedSession',
    default: null
})

export const showSessionChat = atomFamily({
    key: 'showSessionChat',
    default: false,
});

export const guestsForSession = atomFamily({
    key: 'guestsForSession',
    default: null,
});

export const guestConnections = atomFamily({
    key: 'guestConnections',
    default: null,
});

export const projectState = atom({
    key: 'projectState',
    default: null
})

export const selectedProject = atom({
    key: 'selectedProject',
    default: null
})

export const sessionRewards = atomFamily({
    key: 'sessionRewards',
    default: null,
});

export const sessionGuestRewards = atomFamily({
    key: 'sessionGuestRewards',
    default: null,
});

export const exchangeRates = atom({
    key: 'exchangeRates',
    default: null
})

export const bannedList = atom({
    key: 'bannedList',
    default: null
})

export const emailTemplates = atom({
    key: 'emailTemplates',
    default: null
})