import React, { useCallback, useMemo, useRef, useState } from 'react';
import StandardModal from './standard/StandardModal';

import countriesJSON from '../utils/iso-3166-1.alpha2'

const GuestModal = React.memo((props) => {
  const { data, sessionStarted, sessionComplete, include2fa, bypassTechCheck, focusGroup, externalRecruit, segments, allowEdit, allowPostEdit, zoomAliases, getGuestPhoto } = props
  // console.log('guest', props)

  const [isClient, setIsClient] = useState(false);

  const initialValue = useRef()
  initialValue.current = data

  const disabledFn = useCallback(({ id, checkedInTime }) => !allowEdit || (id && ((checkedInTime || sessionComplete) && !allowPostEdit)), [allowEdit, allowPostEdit, sessionComplete])

  const disabledPostFn = useCallback(({ id, checkedInTime }) => !allowEdit || (id && (checkedInTime || sessionComplete)), [allowEdit, sessionComplete])

  const disabledTCFn = useCallback(({ id, checkedInTime, state }) => !allowEdit || (id && (checkedInTime || sessionComplete || state === "Tech-Check")), [allowEdit, sessionComplete])

  const disabledDoneFn = useCallback(({ id }) => !allowEdit || (id && sessionComplete), [allowEdit, sessionComplete])

  const disabledSegmentsFn = useCallback(({ id, checkedInTime, role }) => (!allowEdit || (id && ((checkedInTime || sessionComplete) && !allowPostEdit))) || role !== 'Viewer', [allowEdit, allowPostEdit, sessionComplete])

  const disabledRoleOptions = useCallback(({ id }, option) => (!id && sessionStarted) && option === 'Viewer', [sessionStarted])

  const disabledStateOptions = useCallback(({ id, role, state }, option) => (role === 'Client' ? option !== 'Approved' : !role || (!bypassTechCheck && state === 'Tech-Check' && option !== 'Registered') || (externalRecruit ? (id && option === 'Pre-Qualified') : option === 'Qualified')), [bypassTechCheck, externalRecruit])

  const onRoleUpdate = useCallback((newValue, newParams) => {
    newParams.state = (newValue ? (newValue !== 'Viewer' || bypassTechCheck) ? 'Approved' : 'Registered' : null)
    setIsClient(newParams.role === 'Client')
    return newParams
  }, [bypassTechCheck])

  const zoomAliasValidator = useCallback((value) => {
    return (value && zoomAliases?.length) ? ![...zoomAliases.filter(x => (initialValue.current && initialValue.current.alias) ? x !== initialValue.current.alias : true), ...zoomAliases.filter((s => v => s.has(v) || !s.add(v))(new Set()))].includes(value) : true
  }, [zoomAliases])

  const resolveGuestPhoto = useCallback((value) => {
    // console.log('resolving guest photo', initialValue.current.id, value)
    return getGuestPhoto(initialValue.current.id, value)
  }, [getGuestPhoto])

  const stateOptions = useMemo(() => ([
    ...(focusGroup ? ['Pre-Qualified'] : []),
    'Qualified',
    'Registered',
    'Approved',
    // 'Waitlisted',
    'Denied'
  ]), [focusGroup])

  const segmentFields = useMemo(() => {
    return segments.map(segment => (segment.matrix && segment.matrixKeys) ? segment.matrixKeys.map(mk => ({ ...segment, matrixKey: mk.option })) : segment).flat().map((segment) => {
      const fieldLabel = segment.matrixKey ? `${segment.questionKey} - ${segment.matrixKey}` : segment.questionKey
      const fieldKey = segment.matrixKey ? `segments.${segment.questionKey}.${segment.matrixKey}` : `segments.${segment.questionKey}`
      const field = {
        key: fieldKey,
        label: fieldLabel,
        required: false
      }
      if (segment.freeNumeric) {
        field.type = 'number'
        field.minValue = 0
        field.maxValue = 99
      } else {
        field.type = 'select'
        field.options = segment.answers.map(x => x.answer)
        field.multiple = segment.multiple
      }
      return field
    })
  }, [segments])

  // if (include2fa) {
  // modalProps.tabs[0].fields.push({
  //   key: 'override2fa',
  //   label: 'Override 2-Factor Authentication Requirement',
  //   type: 'boolean',
  //   defaultValue: false,
  //   conditions: [
  //     {
  //       // key: 'role',
  //       condition: ({ role }) => ['Client', 'VIP'].includes(role),
  //     }
  //   ],
  //   disabled: disabledPostFn
  // })
  // }

  const override2fa = useMemo(() => (include2fa ? [{
    key: 'override2fa',
    label: 'Override 2-Factor Authentication Requirement',
    type: 'boolean',
    defaultValue: false,
    conditions: [
      {
        // key: 'role',
        condition: ({ role }) => ['Client', 'VIP'].includes(role),
      }
    ],
    disabled: disabledPostFn
  }] : []), [include2fa, disabledPostFn])

  // if (!bypassTechCheck) {
  //   modalProps.tabs[0].fields.push({
  //     key: 'bypassTechCheck',
  //     label: 'Bypass Tech Check',
  //     type: 'boolean',
  //     defaultValue: false
  //   })
  // }

  return (
    <StandardModal
      modalKey='userModal'
      open
      {...props}
      title={({ id }) => id ? 'Edit Guest' : 'Add New Guest'}
      buttonLabel='Submit'
      tabs={[
        {
          key: 'detailsTab',
          label: 'Details',
          fields: [
            {
              key: 'firstName',
              label: 'First Name',
              placeholder: 'Enter First Name',
              type: 'text',
              required: true,
              disabled: disabledFn
            },
            {
              key: 'lastName',
              label: 'Last Name',
              placeholder: 'Enter Last Name',
              type: 'text',
              required: true,
              disabled: disabledFn
            },
            {
              key: 'email',
              label: 'Email',
              placeholder: 'Enter Email Address',
              type: 'text',
              regexp: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
              required: !isClient,
              disabled: disabledPostFn
            },
            {
              key: 'country',
              label: 'Country',
              type: 'select',
              placeholder: 'Select Country',
              options: countriesJSON,
              optionLabel: 'name',
              optionValueKey: 'alpha-2',
              // valueKey: 'alpha-2',
              multiple: false,
              required: true,
              defaultValue: 'US',
              // onUpdate: onCountriesUpdate,
              disabled: disabledFn
            },
            {
              key: 'phoneNumber',
              label: 'Phone Number',
              placeholder: 'Enter Phone Number (No Spaces/Dashes)',
              type: 'text',
              regexp: /^\d{10}$/,
              required: !isClient,
              disabled: disabledFn
            },
            {
              key: 'alias',
              label: 'Alias',
              placeholder: 'Enter Zoom Alias',
              type: 'text',
              customValidator: zoomAliasValidator,
              required: true, //NEEDED TO TRIGGER CUSTOM VALIDATOR
              disabled: disabledDoneFn
            },
            {
              key: 'groupName',
              label: 'Group Name',
              placeholder: 'Enter Group',
              type: 'text'
            },
            {
              key: 'role',
              label: 'Guest Role',
              placeholder: 'Select Guest Role',
              type: 'select',
              options: [
                'Viewer',
                'Client',
                'VIP'
              ],
              disabledOptions: disabledRoleOptions,
              // onUpdate: [
              //   {
              //     fieldKey: 'state',
              //     value: (x) => (x ? (x !== 'Viewer' || bypassTechCheck) ? 'Approved' : 'Registered' : null)
              //   }
              // ],
              onUpdate: onRoleUpdate,
              multiple: false,
              required: true,
              // defaultValue: 'Viewer',
              disabled: disabledTCFn
            },
            {
              key: 'state',
              label: 'Guest State',
              placeholder: 'Select Guest State',
              type: 'select',
              options: stateOptions,
              disabledOptions: disabledStateOptions,
              multiple: false,
              required: true,
              // defaultValue: bypassTechCheck ? 'Approved' : 'Registered',
              disabled: disabledTCFn
            },
            {
              key: 'recruiterCode',
              label: 'Recruiter Code',
              placeholder: 'Enter Recruiter Code',
              type: 'text',
              conditions: [
                {
                  // key: 'role',
                  condition: ({ role }) => role === 'Viewer',
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'industryCheck',
              label: 'Industry Check',
              placeholder: 'Select Industry Check Status',
              type: 'select',
              options: [
                'Needed',
                'Pending',
                'Approved',
                'Denied'
              ],
              conditions: [
                {
                  // key: 'role',
                  condition: ({ role }) => role === 'Viewer',
                }
              ],
              // disabledOptions: ({ role }, option) => (role === 'Client' ? option !== 'Approved' : !role),
              multiple: false,
              required: false,
              // defaultValue: bypassTechCheck ? 'Approved' : 'Registered',
              disabled: disabledDoneFn
            },
            {
              key: 'focusGroup',
              label: 'Flag for Focus Group?',
              type: 'boolean',
              defaultValue: false,
              disabled: disabledDoneFn,
              conditions: [
                {
                  // key: 'role',
                  condition: ({ role }) => role === 'Viewer',
                }
              ],
            },
            {
              key: 'notes',
              label: 'Notes',
              placeholder: 'Enter Notes for Guest',
              type: 'text',
              large: true,
              // disabled: ({id}) => id && sessionStarted
            },
            {
              key: 'photo',
              label: 'Guest Photo',
              type: 'photo',
              resolver: resolveGuestPhoto,
              conditions: [
                {
                  condition: ({ role }) => role === 'Viewer',
                }
              ],
              disabled: sessionStarted
            },
            {
              key: 'ejectReason',
              label: 'Eject Reason',
              placeholder: 'Enter Eject Reason',
              type: 'text',
              large: true,
              conditions: [
                {
                  // key: 'role',
                  condition: ({ state }) => state === 'Ejected',
                }
              ],
            },
            {
              key: 'doNotPay',
              label: 'Do Not Pay?',
              type: 'boolean',
              defaultValue: false,
              conditions: [
                {
                  // key: 'role',
                  condition: ({ state }) => state === 'Ejected',
                }
              ],
            },
            ...override2fa
          ]
        },
        {
          key: 'segmentsTab',
          label: 'Segments',
          disabled: disabledSegmentsFn,
          fields: segmentFields
        }
      ]}
    />
  );
})

export default GuestModal;
