import config from '../config.json'
// import worker from 'workerize-loader?inline!../utils/fetchWorker' // eslint-disable-line import/no-webpack-loader-syntax
import { wrap } from '../promise-worker.ts'

const initFetchWorker = async () => {
    return await wrap(new Worker(new URL('../utils/fetchWorker', import.meta.url)))
}

export const getGuestsPromise = ({ sessionId, includeDeleted, offset }, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guests?sessionId=${sessionId}&offset=${offset || 0}&includeDeleted=${!!includeDeleted}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data //data.records || data.Items
    })

// export const getAllGuests = ({sessionId, includeDeleted}, token, guests = []) => getGuestsPromise({ sessionId, includeDeleted, offset: guests.length }, token).then((resp) => {
//     guests.push(...resp.records)
//     const more = guests.length !== resp.count;
//     if (more) {
//         return getAllGuests({ sessionId, includeDeleted }, token, guests)
//     } else {
//         return guests
//     }
// })

export const getAllGuests = async ({ sessionId, includeDeleted = false, offset = 0 }, token) => {
    const fetchWorker = await initFetchWorker()
    const data = await fetchWorker.threadedFetch(`https://${config.rest.sessionManagementAPI}/guests`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            params: { sessionId, includeDeleted, offset }
        }, true);
    fetchWorker.terminate();
    return data;
}



export const deleteGuestPromise = (guestId, sessionId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guest`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ id: guestId, sessionId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data;
    })


export const insertGuestPromise = (guest, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guest`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(guest)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const updateGuestPromise = (guest, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guest`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(guest)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const updateGuestNotePromise = (id, note, sessionId, token) => {
    fetch(`https://${config.rest.sessionManagementAPI}/guest-note`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({id, note, sessionId})

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })
}

export const batchUpdateGuestPromise = (sessionId, guestIds, data = {}, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guests`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, ids: guestIds, ...data })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const moveGuestToNewSessionPromise = (guests, newSessionId, oldSessionId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guests/move`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ guests, oldSessionId, newSessionId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const removeFromEmailDenylistPromise = (email, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/denylist/remove`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ email })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const sendCustomEmailPromise = (sessionId, ids, subject, content, options = {}, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/email/custom`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, ids, subject, content, options })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const sendScheduleLinkPromise = (sessionId, ids, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/email/schedule`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, ids })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const sendVerifyLinkPromise = (sessionId, ids, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/email/verify`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, ids })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const sendGuestLinkPromise = (sessionId, ids, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/email/guest`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, ids })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const banGuestPromise = (guestId, sessionId, reason, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guest/ban`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ id: guestId, sessionId, reason })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const checkInGuestPromise = (guestIds, sessionId, suppressEmail = false, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/check-in`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ ids: guestIds, sessionId, suppressEmail })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const techCheckGuestPromise = (guestId, expiration, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/tech-check/start`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ id: guestId, expiration })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const approveGuestPromise = (sessionId, guestId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/tech-check/approve`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, guestId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const waitlistGuestPromise = (sessionId, guestId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/tech-check/waitlist`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, guestId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const denyGuestPromise = (sessionId, guestId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/tech-check/deny`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, guestId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const resetGuestPromise = (sessionId, guestId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/tech-check/reset`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, guestId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const cancelGuestPromise = (sessionId, ids, { cancelNote, noShow }, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/tech-check/cancel`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, ids, cancelNote, noShow })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const uncheckInGuestPromise = (guestId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/check-in`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ id: guestId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const uploadGuestCSVPromse = (file, sessionId, token, mockUpload = false) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guest-csv`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            filename: file.name,
            filetype: file.type,
            mock: mockUpload,
            sessionId
        })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(signedResponseData => {
        const formData = new FormData();
        Object.entries(signedResponseData.fields).forEach(([key, value]) => {
            formData.append(key, value);
        });
        // console.log(file)
        formData.append("file", file.src.file);
        return fetch(signedResponseData.url, {
            method: 'POST',
            mode: 'cors',
            body: formData

        })
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response
        } else {
            throw response
        }
    })

export const getGuestPhotoPromise = (sessionId, guestId, filename, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guest/photo?sessionId=${sessionId}&guestId=${guestId}&filename=${filename}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        // body: JSON.stringify({
        //     filename,
        //     sessionId,
        //     guestId
        // })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    })

export const uploadGuestPhotoPromise = (file, sessionId, guestId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/guest/photo`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            filename: file.name,
            filetype: file.type,
            sessionId,
            guestId
        })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(signedResponseData => {
        const formData = new FormData();
        Object.entries(signedResponseData.fields).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("file", file);
        return fetch(signedResponseData.url, {
            method: 'POST',
            mode: 'cors',
            body: formData

        })
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response
        } else {
            throw response
        }
    })

export const zoomRegisterPromise = (meetingId, guestData, approved, overrideZoomName, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/zoom`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ meetingId, guestData, approved, overrideZoomName })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })