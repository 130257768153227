import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MUIRichTextEditor from 'mui-rte-olive';
import { convertToRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { draftToMarkdown } from 'markdown-draft-js';
import { Typography } from '@mui/material';
const PREFIX = 'RichTextInputField';

const classes = {
  root: `${PREFIX}-root`,
  subroot: `${PREFIX}-subroot`,
  label: `${PREFIX}-label`,
  sublabel: `${PREFIX}-sublabel`,
  base: `${PREFIX}-base`,
  richInput: `${PREFIX}-richInput`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({label}) => ({
  [`& .${classes.root}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.subroot}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.sublabel}`]: {
    flexBasis: '100%',
    // minWidth: '15%',
    // textAlign: 'right',
    marginLeft: label ? 'calc(20% + 16px)' : '10px'
  },
  [`& .${classes.base}`]: {
    flexBasis: '80%',
  },
  [`& .${classes.richInput}`]: {
    border: '1px solid #bdbdbd',
    borderRadius: '4px'
  },
  [`& .MUIRichTextEditor-root`]: {
    // height: '100px',
    border: '1px solid gray',
    borderRadius: '4px',
  },
  [`& .MUIRichTextEditor-toolbar`]: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid gray',
    padding: '5px'
  },
  [`& .MUIRichTextEditor-editor`]: {
    minHeight: '45px',
    width: '90%'
  },
  [`& .MUIRichTextEditor-editorContainer`]: {
    width: '90%'
  },
}));

const RichTextInputField = React.memo(({ value, label, disabled, placeholder, maxLength, onChange, invalid, large, endComponent, sublabel, html }) => {

  const [defaultValue, setDefaultValue] = useState(!!value && typeof value === 'object' ? JSON.stringify(value) : null);

  const loadedRef = useRef(false)
  const valueRef = useRef()
  const editorStateRef = useRef()
  const theme = useTheme();

  const handleChange = useCallback((editorState) => {
    const convertFn = html ? stateToHTML : convertToRaw
    valueRef.current = convertFn(editorState.getCurrentContent())
    onChange(null, valueRef.current)
    editorStateRef.current = editorState
  }, [html, onChange]);

  useEffect(() => {
    if (value?.template) {
      const newValue = { ...value }
      delete newValue.template
      setDefaultValue(JSON.stringify(newValue))
    }
  }, [value])

  useEffect(() => {
    if (!!defaultValue && loadedRef.current) {
      handleChange(editorStateRef.current)
    }
    loadedRef.current = true
  }, [defaultValue, handleChange])

  return (
    <Root label={label}>
      <div className={classes.root}>
        {label && <Typography className={classes.label}>{label}</Typography>}
        <div className={classes.base}>
          <MUIRichTextEditor
            disabled={true}
            // ref={editorRef}
            label={placeholder}
            className={classes.richInput}
            onChange={handleChange}
            onSave={console.log}
            defaultValue={defaultValue}
            toolbarButtonSize="small"
            inlineToolbar={true}
            controls={["title", "bold", "italic", "underline", "strikethrough", "clear", "link", "numberList", "bulletList"]}
            inlineToolbarControls={["bold", "italic", "underline", "strikethrough", "link", "clear"]}
          />
        </div>
      </div>
      {sublabel && <div className={classes.subroot}>
        <Typography variant="caption" className={classes.sublabel}>{typeof sublabel === 'function' ? sublabel() : sublabel}</Typography>
      </div>}
    </Root>
  );
})

// RichTextInputField.defaultProps = {
//   label: 'Text'
// }

export default RichTextInputField;
